import { fetchArtistSuccess } from '../artists/actions/fetchArtist';
import { fetchAlbumSuccess } from '../albums/actions/fetchAlbum';
import { fetchTrackSuccess } from '../tracks/actions';
import { Item } from '~/app/lib/mapper';
import { ItemTypes } from '~/types';
import { Dispatch } from '../types';

/**
 * Called whenever we receive a new version of an Artist/Album/Track
 * from songwhip-api. We centralise these dispatch actions here to be
 * sure that actions are also fired for nested Artists.
 */
const dispatchFetchItemSuccess = (dispatch: Dispatch, item: Item) => {
  switch (item.type) {
    case ItemTypes.ARTIST:
      dispatch(
        fetchArtistSuccess({
          payload: item,
          isPartial: false,
        })
      );

      break;

    case ItemTypes.ALBUM:
      item.artists.forEach((artist) =>
        dispatch(
          fetchArtistSuccess({
            payload: artist,
            isPartial: true,
          })
        )
      );

      dispatch(fetchAlbumSuccess(item));

      break;

    case ItemTypes.TRACK:
      item.artists.forEach((artist) =>
        dispatch(
          fetchArtistSuccess({
            payload: artist,
            isPartial: true,
          })
        )
      );

      dispatch(fetchTrackSuccess(item));
  }
};

export default dispatchFetchItemSuccess;
