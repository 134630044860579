import { GetAllApiPayload, GetAllData, TrackPayload } from '../../types';
import mapTrack from '~/app/lib/mapper/mapTrack';

export default ({
  data,
  links = {},
}: GetAllApiPayload<TrackPayload>): GetAllData => ({
  items: data.map(mapTrack),
  nextPageApiUrl: links.next,
  prevPageApiUrl: links.prev,
});
