import mapArtist from '~/app/lib/mapper/mapArtist';

import { ArtistPayload, GetAllApiPayload, GetAllData } from '../../types';

const mapper = ({
  data,
  links = {},
}: GetAllApiPayload<ArtistPayload>): GetAllData => ({
  items: data.map(mapArtist),
  nextPageApiUrl: links.next,
  prevPageApiUrl: links.prev,
});

export default mapper;
