import { Dispatch } from 'redux';

import * as albumsApi from '~/app/lib/songwhipApi/albums';

import { selectAlbumValue } from '../selectors';
import { State } from '../../types';

import {
  FetchAlbumStartAction,
  AlbumsActionTypes,
  FetchAlbumSuccessAction,
  FetchAlbumErrorAction,
} from '../types';
import dispatchFetchItemSuccess from '../../lib/dispatchFetchItemSuccess';
import { MappedAlbum } from '~/app/lib/mapper';

const fetchAlbumAction =
  ({ albumId }: { albumId: number }) =>
  async (dispatch: Dispatch, getState: () => State) => {
    const state = getState();
    const existingRecord = selectAlbumValue(state, albumId);

    // don't fetch if record already in memory
    if (existingRecord) return;

    dispatch(fetchAlbumStart(albumId));

    try {
      const payload = await albumsApi.getAlbumApi({ albumId });
      dispatchFetchItemSuccess(dispatch, payload);
    } catch (error) {
      dispatch(fetchAlbumError(albumId, error));
    }
  };

export const fetchAlbumStart = (albumId: number): FetchAlbumStartAction => ({
  type: AlbumsActionTypes.FETCH_ALBUM_START,
  albumId,
});

export const fetchAlbumSuccess = (
  payload: MappedAlbum
): FetchAlbumSuccessAction => ({
  type: AlbumsActionTypes.FETCH_ALBUM_SUCCESS,
  payload,
});

export const fetchAlbumError = (
  albumId: number,
  error: Error
): FetchAlbumErrorAction => ({
  type: AlbumsActionTypes.FETCH_ALBUM_ERROR,
  albumId,
  error,
});

export default fetchAlbumAction;
